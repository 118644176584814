<template>
    <main class="view-home">
        <section class="section section-02">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6 offset-sm-3">
                        <img class="img-fluid sticker"
                             v-bind:src="'/media/home/lionel_box.jpg'" />
                    </div>
                </div>
                <article-lionel />
            </div>
        </section>
        <section class="section section-01">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-8 offset-sm-2">
                        <video id="player" width="100%" controls>
                          <source src="/media/home/ode_to_winnie_sub_sd.mp4" type="video/mp4">
                          Your browser does not support HTML video.
                        </video>
                    </div>
                </div>
                <article-winnie />
            </div>
        </section>
        <section class="section section-0">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-4">
                        <img class="img-fluid sticker"
                             v-bind:src="'/media/winnie/winnie_1.png'" />
                    </div>
                    <div class="col-sm-4">
                        <img class="img-fluid sticker"
                             v-bind:src="'/media/winnie/winnie_2.png'" />
                    </div>
                    <div class="col-sm-4">
                        <img class="img-fluid sticker"
                             v-bind:src="'/media/winnie/winnie_3.png'" />
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-1">
            <img class="img-fluid sticker"
                 v-bind:src="'/media/home/sticker.svg'" />
        </section>
        <section class="section section-2">
            <div class="container-fluid">
                <h1 class="section-title">Epilogue</h1>
                <article-about />
            </div>
        </section>
        <section class="section section-3">
            <div class="container-fluid">
                <h1 class="section-title">Close up</h1>
            </div>
            <flickity
                ref="flickity"
                class="flickity"
                v-images-loaded:on.progress="reloadCells"
                :options="flickityOptions">
                <div class="carousel-cell"
                     v-for="(n, index) in 8" :key="index">
                    <img class="carousel-image"
                         v-bind:src="'/media/home/rbtw_' + n + '.jpg'" />
                </div>
            </flickity>
        </section>
        <section class="section section-4">
            <div class="container-fluid">
                <h1 class="section-title">The box set</h1>
            </div>
            <img class="img-fluid section-image"
                 v-bind:src="'/media/home/vali_marco_drawing.jpg'" />
            <div class="section-content">
                <div class="container-fluid text-uppercase">
                    <h1 class="mb-4">
                        Photo book
                        <small class="d-block">
                            Portraits of Vali Myers
                            <span class="d-block">by Marco Bakker</span>
                        </small>
                    </h1>
                    <h1 class="mb-4">
                        Text book
                        <small class="d-block">
                            Gems from Vali Myers letters [1980-2002]
                            <span class="d-block">a choice by Gianni Menichetti</span>
                        </small>
                    </h1>
                    <h1>
                        Signed Silver Gelatine Print
                        <small class="d-block">
                            20 x 25 cm / 8 x 10 inch
                            <span class="d-block">of your choice</span>
                        </small>
                    </h1>
                </div>
            </div>
        </section>
        <section class="section section-5">
            <div class="container-fluid">
                <h1 class="section-title">Marco and Gianni on the project</h1>
                <div class="video">
                    <div class="video-wrapper">
                        <iframe width="560"
                                height="315"
                                src="https://www.youtube.com/embed/O4bQN3QGnUI"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-6">
            <div class="container-fluid">
                <div class="section-content text-center">
                    <router-link
                        :to="{ name: 'catalog' }"
                        class="btn btn-dark btn-lg">
                        > Order now <
                    </router-link>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
export default {
    name: 'view-home',
    data() {
        return {
            flickityOptions: {
                prevNextButtons: false,
                cellAlign: 'left',
                pageDots: true,
                contain: true
            },

        }
    },
    mounted: function() {
        const player = new Plyr('#player');
    },
    methods: {
        reloadCells() {
            this.$refs.flickity.reloadCells()
        }
    }
}
</script>
