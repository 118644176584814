<template>
    <article class="article-winnie">
        <h1 class="section-title">An Ode To Winnie</h1>
        <p class="lead">
            Deaths of tortoises do not always set things in motion.
            But obviously, Winnie, the tortoise of Vali Myers and Gianni Menichetti, had always been a little different.
        </p>
        <p class="lead">
            Winnie had been a confidant and a source of inspiration to Vali and Gianni and since Winnie had always been around, her death in February 2021 caused Gianni to realize that he was now the only living memory of Vali’s legacy. He had a sense that Winnie's death symbolizes the end of an era. In the period of reflection that followed, he wrote 5 poems.
        </p>
        <p class="lead">
            Eventually he reached out to me and together we looked into ways to document all this in a way that would suit the subject matter and his life in the valley. As things go with such endeavors, our project took a surprising turn when we contacted Australian songwriter Jodi Phillis to see if she could turn one of Gianni’s poems into a song, which she did straight away.
            This twist added an interesting third dimension to the ode to Winnie that we were then working on.
        </p>
        <p class="lead">
            Immediately we knew that this project was not going to end up as a digital download or e-book.
            Instead, the project became a rich and beautiful companion to 'Running Before the Wind', consisting of photos, poetry and music.
        </p>
        <div class="text-right">
            <router-link
                :to="{ name: 'catalog' }"
                class="btn btn-dark btn-lg mx-auto"
                style="text-transform: uppercase;"
            >
                > Order now <
            </router-link>
        </div>
    </article>
</template>

<script>
export default {
    name: 'article-winnie'
}
</script>
