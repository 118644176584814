<template>
    <div class="view-catalog">
        <div class="container-fluid">
            <site-catalog v-on:re-build="" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'view-catalog'
}
</script>
