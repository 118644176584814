<template>
    <div class="layout-default">
        <site-header-nav />
        <transition
            name="fade"
            mode="out-in">
            <router-view />
        </transition>
    </div>
</template>

<script>
export default {
    name: 'layout-default'
}
</script>
