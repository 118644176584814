<template>
    <article class="article-lionel">
        <h1 class="section-title">
            Lionel Ziprin<br/>The Poet - Magus of the Lower Eastside
        </h1>
        <p class="lead">
            Lionel Ziprin was a prolific writer, a mystical poet who produced thousands of poems in his time. You wouldn't tell it from his published output, in 1990 a small wooden box with ephemera and a flexi-disc was released and 2017 saw the publishing of his book Songs for Schizoid Siblings. A few contributions to magazines and that's it.
        </p>
        <p class="lead">
            So it's about time this box should be released! The package consists of an eulogy broadside by Louise Landes Levi, an interview with Lionel by his friend Robert Yarra, a memoir chap by Yarra, a facsimile letter, a postcard and a short tape of the man reciting a few of his Schizoid poems. And three archival art prints, signed and numbered by Marco Bakker.
        </p>
        <p class="lead">
            The box, a joint production of Counter Culture Chronicles and Marco Bakker, is made in an edition of 50 copies.
        </p>
        <p class="lead">
            Ira Cohen once stated: "He was much larger than a poet, he was one of the big secret heroes of our time.”
        </p>
        <div class="text-right">
            <router-link
                :to="{ name: 'catalog' }"
                class="btn btn-dark btn-lg mx-auto"
                style="text-transform: uppercase;"
            >
                > Order now <
            </router-link>
        </div>
    </article>
</template>

<script>
export default {
    name: 'article-lionel'
}
</script>
