<template>
    <div class="site-catalog">
        <div class="product"
             v-for="(product, index) in catalog">
            <header class="product-header">
                <h1 class="product-header-title">
                    Edition #{{ catalog.length - index }}
                </h1>
                <h1 class="product-header-price">
                    <span v-if="product.stock">
                        {{ moneyFormat(product.price) }}
                    </span>
                    <span v-else>Sold out</span>
                </h1>
            </header>
            <div class="row">
                <div class="col-lg-8">
                    <img class="product-image img-fluid"
                         v-bind:src="product.image">
                </div>
                <div class="col-lg-4 col-left">
                    <div class="product-body">
                        <template v-if="product.id < 4">
                            <h1 class="mb-4">
                                Photo book
                                <small class="d-block">
                                    Portraits of Vali Myers
                                    <span class="d-block">by Marco Bakker</span>
                                </small>
                            </h1>
                            <h1 class="mb-4">
                                Text book
                                <small class="d-block">
                                    Gems from Vali Myers letters [1980-2002]
                                    <span class="d-block">a choice by Gianni Menichetti</span>
                                </small>
                            </h1>
                            <h1 class="mb-4">
                                {{ product.name }}
                                <small class="d-block">
                                    Signed Silver Gelatine Print
                                    <span class="d-block">20 x 25 cm / 8 x 10 inch</span>
                                </small>
                            </h1>
                            <h1 class="mb-4">
                              Handmade Box
                            </h1>
                        </template>
                        <template v-else-if="product.id === 6">
                            <h1 class="mb-4">
                                {{ product.name }}
                            </h1>
                            <h1 class="mb-4">
                                Photo book
                                <small class="d-block">
                                    Portraits of Vali Myers
                                    <span class="d-block">by Marco Bakker</span>
                                </small>
                            </h1>
                            <h1 class="mb-4">
                                Text book
                                <small class="d-block">
                                    Gems from Vali Myers letters [1980-2002]
                                    <span class="d-block">a choice by Gianni Menichetti</span>
                                </small>
                            </h1>
                            <h1 class="mb-4">
                              Handmade Box
                            </h1>
                        </template>
                        <template v-else-if="product.id === 7">
                            <h1 class="mb-4">
                                {{ product.name }}
                            </h1>
                            <h1 class="mb-4">
                                Photo book
                                <small class="d-block">
                                    Portraits of Vali Myers
                                    <span class="d-block">by Marco Bakker</span>
                                </small>
                            </h1>
                            <h1 class="mb-4">
                                Text book
                                <small class="d-block">
                                    Gems from Vali Myers letters [1980-2002]
                                    <span class="d-block">a choice by Gianni Menichetti</span>
                                </small>
                            </h1>
                            <h1 class="mb-4">
                                Signed and Numbered Print
                                <small class="d-block">
                                    The options are: Vali’s Yellow Dress, Tonino, Walking Stick or Winnie
                                    <span class="d-block">20 x 25 cm / 8 x 10 inch</span>
                                </small>
                            </h1>
                            <h1 class="mb-4">
                                Handmade Box
                            </h1>
                        </template>
                        <template v-else-if="product.id === 10">
                            <h1 class="mb-4">
                                {{ product.name }}
                            </h1>
                            <h1 class="mb-4">
                                Book
                                <small class="d-block">
                                    Marco Bakker<br/>
                                    Gianni Menichetti
                                    <span class="d-block">48 pages</span>
                                </small>
                            </h1>
                            <h1 class="mb-4">
                                7" Single
                                <small class="d-block">
                                    Jodi Phillis<br/>
                                    Gianni Menichetti
                                </small>
                            </h1>
                            <a href="https://kingforwardrecords.com" target="_blank">
                                <img src="/media/king_forward_records.png" style="width:200px">
                            </a>
                            <h1 class="my-4">
                                <small class="d-block">
                                    250 copies worldwide
                                </small>
                            </h1>
                        </template>
                        <template v-else>
                            <h1 class="mb-4">
                                {{ product.name }}
                                <small class="d-block"
                                       v-html="product.description"></small>
                            </h1>
                        </template>
                    </div>
                    <footer class="product-footer">
                        <h1 class="product-footer-price">
                            <span v-if="product.stock">
                                {{ moneyFormat(product.price) }}
                            </span>
                            <span v-else>Sold out</span>
                        </h1>
                        <router-link
                            :to="{ name: 'checkout' }"
                            v-if="cart.items.find(x => x.id === product.id)"
                            class="btn btn-lg btn-dark text-uppercase">
                            Show cart
                        </router-link>
                        <button type="button"
                                class="btn btn-lg btn-dark text-uppercase"
                                :disabled="! product.stock"
                                v-else
                                v-on:click="addItem(product)">
                            <span v-if="product.stock">Add to cart</span>
                            <span v-else>&mdash;</span>
                        </button>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, email, numeric, minLength, sameAs } from "vuelidate/lib/validators"

export default {
    name: 'site-catalog',
    data: function () {
        return {
            catalog: [],
            cart: {
                items: []
            }
        }
    },
    beforeMount: function() {
        this.getProducts()
        this.getCart()
    },
    watch: {
        'cart.items': function() {
            localStorage.setItem('cart', JSON.stringify(this.cart))
            this.$root.$emit('cartUpdated')
        }
    },
    methods: {
        getCart() {
            if(localStorage.getItem('cart')) {
                this.cart = JSON.parse(localStorage.getItem('cart'))
            }
        },
        addItem(product) {
            if(! this.cart.items.find(x => x.id === product.id)) {
                this.cart.items.push({
                    id: product.id,
                    qty: 1
                })
            }
        },
        moneyFormat: function(amount) {
            return new Intl.NumberFormat('nl-NL',
                { style: 'currency', currency: 'EUR' }
            ).format(amount / 100)
        },
        getProducts: function() {
            axios
                .get('/api/v1/products')
                .then(response => {
                    this.catalog = response.data
                    this.$emit('re-build', true)
                })
                .catch(e => {
                    this.error = true
                    console.log(e)
                })
        }
    }
}
</script>
