<template>
    <div class="site-order">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-4 text-uppercase">
                        <h2 class="mb-3">Cart</h2>
                        <ul class="list-group mb-4">
                            <li class="list-group-item d-flex justify-content-between align-items-center"
                                v-if="cart.items.length == 0">
                                No products selected
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center p-0 pr-3"
                                v-for="(item, index) in cart.items"
                                v-else>
                                <div class="btn-group-vertical">
                                    <button type="button" 
                                            class="btn btn-sm btn-dark"
                                            v-on:click="item.qty < catalog.find(x => x.id === item.id).stock ? 
                                                        item.qty += 1 : 
                                                        item.qty = catalog.find(x => x.id === item.id).stock">
                                        <font-awesome-icon :icon="['far', 'angle-up']" />
                                    </button>
                                    <button type="button" 
                                            class="btn btn-sm btn-dark"
                                            v-on:click="item.qty > 1 ? item.qty -= 1 : item.qty = 1">
                                        <font-awesome-icon :icon="['far', 'angle-down']" />
                                    </button>
                                </div>
                                <h4 class="mb-0">
                                    {{ item.qty }}X
                                    {{ catalog.find(x => x.id === item.id).name }}
                                </h4>
                                <a href="#"
                                   class="text-danger" 
                                   v-on:click.prevent="removeItem(index)">
                                    <font-awesome-icon :icon="['far', 'times']" />
                                </a>
                            </li>
                        </ul>
                        <ul class="list-group list-group-flush text-right mb-3 h4">
                            <li class="list-group-item d-flex justify-content-between">
                                <span>Subtotal</span>
                                {{ moneyFormat(subTotal) }}
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-8 justify-content-end">
                        <form @submit.prevent="handleSubmit"
                              v-if="cart.items.length"
                              id="orderForm">
                            <h2 class="text-uppercase mb-3">Contact information</h2>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <input type="text" 
                                           class="form-control" 
                                           placeholder="First name"
                                           v-model="fields.name"
                                           :class="{ 'is-invalid': submitted && $v.fields.name.$error }">
                                    <div class="invalid-feedback"
                                         v-if="submitted && !$v.fields.name.any" >
                                        Please enter a valid first name.
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <input type="text" 
                                           class="form-control" 
                                           placeholder="Last name"
                                           v-model="fields.surname"
                                           :class="{ 'is-invalid': submitted && $v.fields.surname.$error }">
                                    <div class="invalid-feedback"
                                         v-if="submitted && !$v.fields.surname.any" >
                                        Pleast enter a valid last name.
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-8">
                                    <input type="email" 
                                           class="form-control"
                                           placeholder="E-mail address"
                                           v-model="fields.email"
                                           :class="{ 'is-invalid': submitted && $v.fields.email.$error }">
                                    <div class="invalid-feedback"
                                         v-if="submitted && !$v.fields.email.any" >
                                        Please enter a valid e-mail address.
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <input type="text" 
                                           class="form-control"
                                           placeholder="Phone number"
                                           v-model="fields.phone"
                                           :class="{ 'is-invalid': submitted && $v.fields.phone.$error }">
                                    <div class="invalid-feedback"
                                         v-if="submitted && !$v.fields.phone.any" >
                                        Please enter a valid phone number.
                                    </div>
                                </div>
                            </div>
                            <h2 class="text-uppercase my-3">Shipping address</h2>
                            <div class="form-row">
                                <div class="form-group col">
                                    <input type="text" 
                                           class="form-control mb-3"
                                           placeholder="Street address"
                                           v-model="fields.line_1"
                                           :class="{ 'is-invalid': submitted && $v.fields.line_1.$error }">
                                    <input type="text" 
                                           class="form-control"
                                           placeholder="Apartment, studio, or floor" 
                                           v-model="fields.line_2">
                                    <div class="invalid-feedback"
                                         v-if="submitted && !$v.fields.line_1.required" >
                                        Pleast enter a valid street address.
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <input type="text" 
                                           class="form-control"
                                           placeholder="Zipcode"
                                           v-model="fields.zip"
                                           :class="{ 'is-invalid': submitted && $v.fields.zip.$error }">
                                    <div class="invalid-feedback"
                                         v-if="submitted && !$v.fields.zip.required" >
                                        Pleast enter a valid zipcode.
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <input type="text" 
                                           class="form-control"
                                           placeholder="City"
                                           v-model="fields.city"
                                           :class="{ 'is-invalid': submitted && $v.fields.city.$error }">
                                    <div class="invalid-feedback"
                                         v-if="submitted && !$v.fields.city.required" >
                                        Pleast enter a valid City.
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <input type="text" 
                                           class="form-control"
                                           placeholder="State"
                                           v-model="fields.state">
                                </div>
                            </div>                                
                            <div class="form-group">
                                <select class="form-control" 
                                        v-model="fields.country"
                                        :class="{ 'is-invalid': submitted && $v.fields.country.$error }">
                                    <option v-for="country in countries"
                                            v-bind:value="country.code">
                                        {{ country.name }}
                                    </option>
                                </select>
                                <div class="invalid-feedback"
                                     v-if="submitted && !$v.fields.country.required" >
                                    Pleast enter a valid country.
                                </div>
                            </div>
                            <ul class="list-group list-group-flush text-right mb-3 h4">
                                <li class="list-group-item d-flex justify-content-between">
                                    <span>Subtotal</span>
                                    {{ moneyFormat(subTotal) }}
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                    <span>Shipping</span>
                                    {{ moneyFormat(shipping) }}
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                    <span>Total</span>
                                    {{ moneyFormat(total) }}
                                </li>
                            </ul>
                            <div class="text-right">
                                <button class="btn btn-lg btn-dark text-uppercase"
                                        type="button"
                                        v-on:click="handleSubmit()">
                                    Proceed to payment
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, email, numeric, minLength, sameAs } from "vuelidate/lib/validators"
import countryList from './../../json/countries.json'

export default {
    name: 'site-order',
    data: function () {
        return {
            catalog: [],
            fields: {
                name:        "",
                surname:     "",
                email:       "",
                phone:       "",
                line_1:      "",
                line_2:      "",
                zip:         "",
                city:        "",
                state:       "",
                country:     "NL"
            },
            countries: countryList,
            cart: {
                items: []
            },
            submitted: false
        }
    },
    beforeMount: function() {
        this.getProducts()
    },
    validations: {
        fields: {
            name: {
                minLength: minLength(2),
                required
            },
            surname: {
                minLength: minLength(2),
                required
            },
            email: {
                required,
                email
            },
            phone: {
                required
            },
            line_1: {
                required
            },
            line_2: {
            },
            zip: {
                required
            },
            city: {
                required
            },
            state: {
            },
            country: {
                required
            }
        }
    },
    computed: {
        subTotal: function() {
            let sum = this.cart.items.reduce((accumulator, item, index) => {
                return accumulator + (item.qty * this.catalog.find(x => x.id === item.id).price)
            }, 0)
            return sum
        },
        shipping: function() {
            if(this.countries.length && this.cart.items.length) {
                let country = this.countries.find(x => x.code === this.fields.country)
                let europe = [
                    'AT','BE','BG','HR','CY','CZ','DK','EE','FI','FR','DE','GR','HU',
                    'IE','IT','LV','LT','LU','MT','PL','PT','RO','SK','SI','ES','SE'
                ]

                if(country.code === "NL") return 1000
                if(europe.find(code => code === country.code)) return 2000

                return 3000
            }
            return 0
        },
        total: function() {
            return this.subTotal + this.shipping
        }
    },
    watch: {
        cart: { 
            deep: true,
            handler() {
                localStorage.setItem('cart', JSON.stringify(this.cart))
                this.$root.$emit('cartUpdated')
            }
        }
    },
    methods: {
        getCart() {
            if(localStorage.getItem('cart')) {
                this.cart = JSON.parse(localStorage.getItem('cart'))
            }
        },
        removeItem(index) {
            this.cart.items.splice(index, 1)
        },
        handleSubmit(e) {
            this.submitted = true
            this.$v.$touch()

            if (this.$v.$invalid) return

            this.checkout()
        },
        moneyFormat: function(amount) {
            return new Intl.NumberFormat('nl-NL', 
                { style: 'currency', currency: 'EUR' }
            ).format(amount / 100)
        },
        getProducts: function() {
            axios
                .get('/api/v1/products')
                .then(response => {
                    this.catalog = response.data
                    this.getCart()
                })
                .catch(e => {
                    this.error = true
                    console.log(e)
                })
        },
        getCountries: function() {
            delete axios.defaults.headers.common["X-CSRF-TOKEN"]
            axios
                .get('https://restcountries.eu/rest/v2/all')
                .then(response => {
                    this.countries = response.data
                })
                .catch(e => {
                    this.error = true
                    console.log(e)
                })
        },
        checkout: function() {
            axios
                .put('/api/v1/checkout', {
                    fields: this.fields,
                    items: this.cart.items,
                    shipping: this.shipping
                })
                .then(response => {
                    if(response.data.checkout) {
                        localStorage.removeItem('cart')
                        return window.location = response.data.checkout
                    }
                })
                .catch(e => {
                    this.error = true
                    console.log(e)
                })
        }
    }
}
</script>
