import Vue from "vue"
import Router from "vue-router"

import ViewHome     from "../views/ViewHome.vue"
import ViewCatalog  from "../views/ViewCatalog.vue"
import ViewCheckout from "../views/ViewCheckout.vue"
import ViewLove     from "../views/ViewLove.vue"

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: "/",
            name: "home",
            component: ViewHome
        },
        {
            path: "/catalog",
            name: "catalog",
            component: ViewCatalog
        },
        {
            path: "/checkout",
            name: "checkout",
            component: ViewCheckout
        },
        {
            path: "/love",
            name: "love",
            component: ViewLove
        }
    ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    mode: "history",
    base: "/"
})

export default router
