<template>
    <div class="site-intro">
        <full-page ref="fullpage" :options="options" id="fullpage">
            <div class="section section-0">
                <div class="section-content text-uppercase text-center">
                    <h1 class="sticker">Available now!</h1>
                    <a href="#" 
                       class="next"
                       v-on:click.prevent="$refs.fullpage.api.moveSectionDown()">
                        <small class="d-block">
                            Epilogue
                        </small>
                        <font-awesome-icon 
                            :icon="['far', 'angle-down']" 
                            size="3x" />
                    </a>
                </div>
            </div>
            <div class="section section-1">
                <div class="section-content">
                    <h1></h1>
                    <site-about />
                    <a href="#" 
                       class="next text-uppercase text-center"
                       v-on:click.prevent="$refs.fullpage.api.moveSectionDown()">
                       <small class="d-block">
                            The box set
                        </small>
                        <font-awesome-icon 
                            :icon="['far', 'angle-down']" 
                            size="3x" />
                    </a>
                </div>
            </div>
            <div class="section section-2">
                <div class="section-content text-uppercase text-center">
                    <h1></h1>
                    <div class="container-fluid">
                        <h1 class="title display-4">
                            Photo book
                            <small class="sub-title d-block">
                                Portraits of Vali Myers
                                <span class="d-block">by Marco Bakker</span>
                            </small>
                        </h1>
                        <h1 class="title display-4">
                            Text book
                            <small class="sub-title d-block">
                                Gems from Vali Myers letters [1980-2002]
                                <span class="d-block">a choice by Gianni Menichetti</span>
                            </small>
                        </h1>
                        <h1 class="title display-4">
                            Signed Silver Gelatine Print 
                            <small class="sub-title d-block">
                                20 x 25 cm / 8 x 10 inch
                                <span class="d-block">of your choice</span>
                            </small>
                        </h1>
                    </div>
                    <a href="#" 
                       class="next"
                       v-on:click.prevent="$refs.fullpage.api.moveSectionDown()">
                        <small class="d-block">
                            Marco and Gianni on the project
                        </small>
                        <font-awesome-icon 
                            :icon="['far', 'angle-down']" 
                            size="3x" />
                    </a>
                </div>
            </div>
            <div class="section section-3"
                 v-bind:style="{ backgroundImage: 'url(/media/home/rbtw_7.jpeg)' }">
                <div class="section-content text-center text-uppercase">
                    <h1></h1>
                    <div class="video">
                        <div class="video-wrapper">
                            <iframe width="560" 
                                    height="315" 
                                    src="https://www.youtube.com/embed/O4bQN3QGnUI" 
                                    frameborder="0" 
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen></iframe>
                        </div>
                    </div>
                    <a href="#" 
                       class="next"
                       v-on:click.prevent="$refs.fullpage.api.moveSectionDown()">
                        <small class="d-block">
                            Select and order
                        </small>
                        <font-awesome-icon 
                            :icon="['far', 'angle-down']" 
                            size="3x" />
                    </a>
                </div>
            </div>
            <div class="section section-4">
                <div class="section-content">
                    <router-link 
                        :to="{ name: 'catalog' }"
                        class="btn btn-primary btn-lg">
                        Order now!
                    </router-link>
                </div>
            </div>
        </full-page>
    </div>
</template>{}

<script>
export default {
    name: 'site-intro',
    data: function () {
        return {
            options: {
                anchors: ['welcome', 'epilogue', 'box-set', 'video', 'order'],
                licenseKey: '310A839C-4A42405A-914A4513-5BAD9292',
                scrollHorizontally: true,
                scrollOverflow: true
            },
        }
    },
    mounted: function() {
        this.reBuild()
    },
    methods: {
        reBuild: function(e) {
            this.$refs.fullpage.api.reBuild()
        }
    }
}
</script>
