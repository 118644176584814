<template>
    <article class="article-about">
        <p class="lead">
            Dutch photographer Marco Bakker first met Vali Myers in the year 2000. He took wonderful portraits of her in the wild canyon in South Italy where she lived, and over the years, he faithfully returned to Italy numerous times to photograph me, Vali’s companion for more than three decades. Now, Marco is publishing a selection of those photographs accompanied by excerpts from Vali’s letters.
        </p>
        <p class="lead">
            The limited-edition box set <strong>Running Before the Wind</strong> will contain two volumes: one featuring Marco’s photographs beside Vali’s handwritten comments, and the other a collection of Vali’s thoughts on life and nature — which I selected from her letters to me, and an original photographic print signed and numbered by Marco Bakker.
        </p>
        <p class="lead">&mdash; Gianni Menichetti <em>Poet-Artist</em></p>
    </article>
</template>

<script>
export default {
    name: 'article-about'
}
</script>
