require('./bootstrap');

window.Vue = require('vue');

window.Vue.config.productionTip = false;

// Import and register Vue Router

import router from "./router/index";

// Import Font Awesome components

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'

library.add(far)
library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

// Import Validation

import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

// Import Flickity

import Flickity from 'vue-flickity'

Vue.component('flickity', Flickity)

// Import ImagesLoaded

import ImagesLoaded from 'vue-images-loaded'

Vue.directive('images-loaded', ImagesLoaded)

// Import Fullpage

import 'fullpage.js/vendors/scrolloverflow'
import VueFullPage from 'vue-fullpage.js'

Vue.use(VueFullPage);

// Import Components

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Create Vue application

const app = new Vue({
    el: '#app',
    router
});
