<template>
    <div class="site-header-nav">
        <header class="site-header">
            <h1 class="site-header-title">Running<span class="d-block">before</span>the wind</h1>
            <router-link 
                :to="{ name: 'checkout' }"
                v-if="cart.items.length"
                class="site-cart">
                <font-awesome-layers 
                    class="fa-fw fa-2x">
                    <font-awesome-icon 
                        :icon="[ 'far', 'shopping-bag' ]"
                        :style="{ color: 'white' }" />
                    <font-awesome-layers 
                        class="fa-layers-counter fa-layers-bottom-right"
                        :style="{ background: 'white', color: 'black' }">
                        {{ cart.items.length }}
                    </font-awesome-layers>
                </font-awesome-layers>
            </router-link>
        </header>
        <nav class="site-nav">
            <router-link 
                :to="{ name: 'home' }"
                class="site-nav-item">
                Home
            </router-link>
            <router-link 
                :to="{ name: 'catalog' }"
                class="site-nav-item">
                Catalog
            </router-link>
            <router-link 
                :to="{ name: 'checkout' }"
                class="site-nav-item">
                Cart
            </router-link>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'site-header-nav',
    data: function () {
        return {
            cart: {
                items: []
            }
        }
    },
    beforeMount: function() {
        this.getCart()
    },
    mounted() {
        this.$root.$on('cartUpdated', () => {
            this.getCart()
        })
    },
    methods: {
        getCart() {
            if(localStorage.getItem('cart')) {
                this.cart = JSON.parse(localStorage.getItem('cart'))
            }
        }
    }
}
</script>