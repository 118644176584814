<template>
    <div class="view-checkout">
        <site-order />
    </div>
</template>

<script>
export default {
    name: 'view-checkout'
}
</script>
