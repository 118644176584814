<template>
    <router-link 
        :to="{ name: 'checkout' }"
        class="site-cart">
        <font-awesome-layers 
            class="fa-fw fa-3x">
            <font-awesome-icon 
                :icon="[ 'fas', 'circle' ]" />
            <font-awesome-icon 
                :icon="[ 'far', 'shopping-bag' ]"
                transform="shrink-8" 
                :style="{ color: 'white' }" />
            <font-awesome-layers 
                class="fa-layers-counter fa-layers-bottom-right text-monospace"
                :style="{ background: 'red' }">
                {{ items }}
            </font-awesome-layers>
        </font-awesome-layers>
    </router-link>
</template>

<script>
export default {
    name: 'site-cart',
    data: function () {
        return {
            items: 2
        }
    }
}
</script>