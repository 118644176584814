<template>
    <div class="view-love">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 offset-lg-1">
                    <img class="img-fluid mt-3"
                         v-bind:src="'/media/home/love.jpeg'">
                </div>
                <div class="col-lg-5">
                    <p class="lead mt-4">
                        Thank you so much for your order, thanks to you its possible to finish the project.
                    </p>
                    <p class="lead">
                        `An Ode To Winnie` will be ready the summer of 2022. If you ordered anything else from the catalog it will be shipped this week.
                    </p>
                    <p class="lead">
                        Much love, Marco Gianni & Jodi
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'view-love'
}
</script>
